<template>
<div class="container">
    <el-carousel class="banner">
        <el-carousel-item v-for="item in 4" :key="item">
            <img class="img" src="./images/banner.png">
        </el-carousel-item>
    </el-carousel>

    <div class="content">
        <div class="title">
            资管产品推荐
        </div>
        <div class="sub-title">
            理财产品在金融监管部门的制度里被称为【资产管理产品】，简称资管产品。资管产品是一种载体，金融机构作为管理人通过这个载体对普通大众委托的资金进行投资和管理。
        </div>
        <div class="table-list">
            <div v-for="item in tableList" :key="item.id" class="table-item">
                <div class="top">
                    <div class="left">
                        <div class="name">
                            创金合信芯片产业股票
                        </div>
                        <div class="center">
                            <el-image class="img-style" :src="require('./images/plan.png')" fit="contain" />
                        </div>
                    </div>

                    <div class="btn">
                        预约咨询
                    </div>
                </div>
                <div class="bottom">
                    <div class="bottom-itemA">
                        <div class="color-A">
                            {{ item.changeDay }}
                        </div>
                        <div class="color-D">
                            业绩比较基准
                        </div>
                    </div>
                    <div class="bottom-itemB">
                        <div class="color-B">
                            {{ item.startInvestAmt }}万
                        </div>
                        <div class="color-D">
                            起投金额
                        </div>
                    </div>
                    <div class="bottom-itemC">
                        <div class="color-C">
                            {{ item.sellConfirmDate }}
                        </div>
                        <div class="color-D">
                            赎回到账
                        </div>
                    </div>
                    <div class="bottom-itemD">
                        <div class="color-C">
                            {{ item.startInvestAmt }}万
                        </div>
                        <div class="color-D">
                            起投金额
                        </div>
                    </div>
                    <div class="bottom-itemE">
                        <div class="color-C">
                            --
                        </div>
                        <div class="color-D">
                            投资范围
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="loading" v-if="isLoading">
        <el-image class="img-style" :src="require('./images/loading.png')" fit="contain" />
    </div>
</div>
</template>

<script>
import {
    selectDictLabel
} from "@/utils/dicts";
import {
    highFinancialAsset
} from "@/api/highFinancial";
export default {
    name: "HighFinancialAsset",
    components: {},
    data() {
        return {
            total: 0,
            page: {
                pageNum: 1,
                pageSize: 10
            },
            tableList: [],
            isLoading: false,

        }
    },
    created() {
        this.highFinancialAsset();
    },
    mounted() {
        this.scrollBottom();
    },
    methods: {
        scrollBottom() {
            window.onscroll = () => {
                //变量scrollTop是滚动条滚动时，距离顶部的距离
                var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
                //变量windowHeight是可视区的高度
                var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
                //变量scrollHeight是滚动条的总高度
                var scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
                //滚动条到底部的条件
                if (scrollTop + windowHeight == scrollHeight) {
                    //到了这个就可以进行业务逻辑加载后台数据了

                    this.$message('到底了');
                    this.isLoading = true;
                    setTimeout(() => {
                        this.isLoading = false;
                    }, 1000);
                    // if (!this.isLoading) {
                    //     this.page.pageNum++;
                    //     this.highFinancialAsset();
                    // }

                }
            }
        },
        highFinancialAsset() {
            this.isLoading = true;
            highFinancialAsset({
                //查询传参
                ...this.page,
                privateFlag: "1" //私募标识 0：公募 1：私募
            }).then(r => {
                const {
                    code,
                    data: {
                        data = [],
                        total
                    }
                } = r;
                if (code == 200) {

                    this.tableList = data;
                    this.total = total;
                    this.isLoading = false;
                }
            }).catch(() => {
                this.isLoading = false;
            })
        },
        formatter(val, options) {
            return selectDictLabel(val, options);
        },

        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
            this.page.pageNum = 1;
            this.page.pageSize = val;
            this.highFinancial();
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
            this.page.pageNum = val;
            this.highFinancial();
        }
    },

};
</script>

<style lang="scss" scoped>
.container {
    // padding-bottom: 122px;

    .banner {
        width: 100%;
        height: 356px;

        .img {
            width: 100%;
            height: 100%;
        }
    }

    .content {
        margin: 0 auto;
        width: 1200px;

        .title {
            font-style: normal;
            font-weight: bold;
            font-size: 36px;
            text-align: center;
            color: #1F1F1F;
        }

        .sub-title {
            margin-top: 24px;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            color: #A5A5A5;

        }

        .table-list {
            margin-top: 60px;
            padding-bottom: 30px;

            .table-item {
                margin-top: 24px;
                background: #FFFFFF;
                box-shadow: 0px 4px 12px #EDF1F7;
                padding: 0 30px 38px 27px;

                .top {
                    padding: 34px 0 18px 30px;
                    display: flex;
                    justify-content: space-between;

                    .left {
                        border-bottom: 1px solid #EDF1F7;
                        display: flex;
                        align-items: center;
                        padding-bottom: 18px;

                        .name {
                            font-style: normal;
                            font-weight: bold;
                            font-size: 20px;
                            line-height: 20px;
                            color: #1F1F1F;
                            flex-shrink: 0;
                        }

                        .center {
                            margin-left: 514px;

                            .img-style {
                                width: 79px;
                                height: 16px;
                            }
                        }
                    }

                    .btn {
                        margin-left: 157px;
                        position: relative;
                        top: 22px;
                        width: 178px;
                        height: 45px;
                        background: url('./images/btn1.png') no-repeat;
                        text-align: center;
                        line-height: 45px;
                        font-style: normal;
                        font-weight: bold;
                        font-size: 16px;
                        color: #FFFFFF;

                        // &.avilable {}
                    }
                }

                .bottom {
                    display: flex;

                    .bottom-itemA {
                        .color-A {
                            font-style: normal;
                            font-weight: bold;
                            font-size: 24px;
                            color: #D43F3F;
                        }

                        .color-D {
                            margin-top: 20px;
                            font-style: normal;
                            font-weight: 290;
                            font-size: 12px;
                            color: #25293D;
                        }

                    }

                    .bottom-itemB {
                        margin-left: 83px;

                        .color-B {
                            font-style: normal;
                            font-weight: normal;
                            font-size: 16px;
                            color: #CE9B63;
                        }

                        .color-D {
                            margin-top: 30px;
                            font-style: normal;
                            font-weight: 290;
                            font-size: 12px;
                            color: #25293D;
                        }

                    }

                    .bottom-itemC {
                        margin-left: 92px;

                        .color-C {
                            font-style: normal;
                            font-weight: normal;
                            font-size: 16px;
                            color: #1F1F1F;
                        }

                        .color-D {
                            margin-top: 30px;
                            font-style: normal;
                            font-weight: 290;
                            font-size: 12px;
                            color: #25293D;
                        }

                    }

                    .bottom-itemD {
                        margin-left: 92px;

                        .color-C {
                            font-style: normal;
                            font-weight: normal;
                            font-size: 16px;
                            color: #1F1F1F;
                        }

                        .color-D {
                            margin-top: 30px;
                            font-style: normal;
                            font-weight: 290;
                            font-size: 12px;
                            color: #25293D;
                        }

                    }

                    .bottom-itemE {
                        margin-left: 84px;

                        .color-C {
                            font-style: normal;
                            font-weight: normal;
                            font-size: 16px;
                            color: #1F1F1F;
                        }

                        .color-D {
                            margin-top: 30px;
                            font-style: normal;
                            font-weight: 290;
                            font-size: 12px;
                            color: #25293D;
                        }

                    }

                }

            }
        }
    }

    .loading {
        height: 122px;
        display: flex;
        justify-content: center;
        align-items: center;

        .img-style {
            width: 258px;
            height: 52px;
        }
    }
}
</style>
